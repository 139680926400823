// JsFromRoutes CacheKey ae3e95e85bc41fe2c1a9fe837987a10c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/members'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/members'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/members/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/members/:id'),
}
