import { profile } from "@/api";
import LogoutButton from "@/components/logout-button";
import AvatarWithFallback from "@/components/ui/avatar-with-fallback";
import { Button } from "@/components/ui/button";
import useAuth from "@/lib/hooks/use-auth";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import { router } from "@moraki/inertia-react";
import { t } from "i18next";

export default function Profile() {
  const auth = useAuth();
  const workspace = useCurrentWorkspace();

  const goToProfile = () => router.get(profile.edit.path(workspace));

  return (
    <div className="flex flex-col w-full justify-center gap-2">
      <Button
        variant="ghost"
        className="gap-4 flex-grow items-center justify-start text-sm font-medium text-secondary-foreground hover:bg-white hover:text-primary"
        onClick={goToProfile}
        size="sm"
      >
        <AvatarWithFallback
          avatarable={auth}
          size="sm"
          onClick={goToProfile}
          className="cursor-pointer"
        />
        <div className="truncate">{auth.name}</div>
      </Button>
      <LogoutButton>{t("logout")}</LogoutButton>
    </div>
  );
}
