// JsFromRoutes CacheKey 4e5a6555c093a6aee559312a3412c8d2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/funds'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/funds'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/funds/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/funds/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/funds/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/funds/:id'),
}
