// JsFromRoutes CacheKey 0231f470823f63244f8b71d6ba077b8e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/valuations'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/valuations'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/valuations/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/valuations/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/valuations/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:company_id/valuations/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:company_id/valuations/:id'),
}
