// JsFromRoutes CacheKey 132d0c391d31d44c3f344e6446312304
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/financials'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/financials'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/financials/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/financials/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/financials/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:company_id/financials/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:company_id/financials/:id'),
}
