// JsFromRoutes CacheKey d35d34a903e5435aed04b01ab230c794
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:id'),
}
