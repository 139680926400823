import {
  adminWorkspaces,
  aiAgent,
  companies,
  companiesFinancials,
  companiesInvestments,
  companiesValuations,
  firm,
  funds,
  members,
  otpApp,
  password,
  profile,
  security,
  workspaces,
} from "@/api";
import Icon from "@/components/ui/icon";
import PingIndicator from "@/components/ui/ping-indicator";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import usePageProp from "@/lib/hooks/use-page-prop";
import { AdminPending } from "@/types/serializers";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface Tab {
  name: ReactNode;
  href: string;
  isActive: boolean;
  icon: ReturnType<typeof Icon>;
}

const RedDot = () => <PingIndicator color="red" className="mt-1.5 ml-1.5" />;

export const getDashboardTabs = (): Tab[] => {
  const workspace = useCurrentWorkspace();
  const { t } = useTranslation();
  return [
    {
      name: t("overview"),
      href: workspaces.show.path(workspace),
      isActive: workspaces.show.isCurrent(),
      icon: <Icon size={24} name="chart-spline" />,
    },
    {
      name: t("funds.title"),
      href: funds.index.path(workspace),
      isActive: [
        funds.index.isCurrent(),
        funds.edit.isCurrent(),
        funds.new.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="piggy-bank" />,
    },
    {
      name: t("companies.title"),
      href: companies.index.path(workspace),
      isActive: [
        companies.index.isCurrent(),
        companies.edit.isCurrent(),
        companies.new.isCurrent(),
        companies.show.isCurrent(),
        companiesInvestments.index.isCurrent(),
        companiesInvestments.new.isCurrent(),
        companiesValuations.index.isCurrent(),
        companiesValuations.new.isCurrent(),
        companiesFinancials.index.isCurrent(),
        companiesFinancials.new.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="building" />,
    },
    null, // separator
    {
      name: t("agent.title"),
      href: aiAgent.show.path(workspace),
      isActive: aiAgent.show.isCurrent(),
      icon: <Icon size={24} name="sparkles" />,
    },
    {
      name: t("firm.title"),
      href: firm.show.path(workspace),
      isActive: firm.show.isCurrent(),
      icon: <Icon size={24} name="briefcase" />,
    },
    {
      name: t("settings.title"),
      href: workspaces.edit.path(workspace),
      isActive: [
        workspaces.edit.isCurrent(),
        profile.edit.isCurrent(),
        otpApp.new.isCurrent(),
        password.edit.isCurrent(),
        security.show.isCurrent(),
        members.index.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="settings" />,
    },
  ] as Tab[];
};

export const getAdminTabs = (): Tab[] => {
  const pending = usePageProp<AdminPending>("pending");

  return [
    {
      name: <>Workspaces {pending.workspaces && <RedDot />}</>,
      href: adminWorkspaces.index.path(),
      isActive: [
        adminWorkspaces.index.isCurrent(),
        adminWorkspaces.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="briefcase" />,
    },
  ] as Tab[];
};
