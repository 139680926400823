// JsFromRoutes CacheKey 7525bb5ac302a68e5f68de36da4bd1d1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/workspace/new'),
  create: /* #__PURE__ */ definePathHelper('post', '/workspace'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:slug/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:slug'),
}
