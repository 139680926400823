// JsFromRoutes CacheKey 57d8879bb84aea8bb7da8ab6191205cf
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/workspaces/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/workspaces/:id'),
}
