import { workspaces } from "@/api";
import MainPanel from "@/layouts/components/main-panel";
import Nav from "@/layouts/components/nav";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import { ReactNode } from "react";
import { getDashboardTabs } from "./components/nav/tabs";
import Profile from "./components/profile";
import WorkspaceDropdown from "./components/workspace-dropdown";
import DashboardProviders from "./providers/dashboard-providers";

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const workspace = useCurrentWorkspace();

  return (
    <DashboardProviders>
      <Nav
        header={<WorkspaceDropdown />}
        tabs={getDashboardTabs()}
        footer={<Profile />}
        theme={{ header: { logo: { href: workspaces.show.path(workspace) } } }}
      />
      <MainPanel>{children}</MainPanel>
    </DashboardProviders>
  );
};

export default (page: ReactNode) => <DashboardLayout>{page}</DashboardLayout>;
