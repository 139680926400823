// JsFromRoutes CacheKey 3cc571bf4cb1393012f257982abd2d1a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/otp_app/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/otp_app'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/otp_app'),
}
