import { cn } from "@/lib/utils";
import {
  AdminCurrentAdminUser,
  User,
  WorkspaceIndex,
} from "@/types/serializers";
import { AvatarProps } from "@radix-ui/react-avatar";
import { ReactNode } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";

interface AvatarWithFallbackProps extends AvatarProps {
  avatarable: WorkspaceIndex | User | AdminCurrentAdminUser;
  size?: "sm" | "md" | "lg";
  fallback?: ReactNode;
}

const SIZE_CHART = {
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
};

const TEXT_SIZE_CHART = {
  sm: "text-normal",
  md: "text-lg",
  lg: "text-xl",
};

const FALLBACK_COLORS = [
  "bg-blue-100",
  "bg-blue-200",
  "bg-green-100",
  "bg-green-200",
  "bg-red-100",
  "bg-red-200",
  "bg-yellow-100",
  "bg-yellow-200",
  "bg-indigo-100",
  "bg-indigo-200",
  "bg-purple-100",
  "bg-purple-200",
  "bg-pink-100",
  "bg-pink-200",
  "bg-gray-100",
  "bg-gray-200",
  "bg-blue-gray-100",
  "bg-blue-gray-200",
  "bg-teal-100",
  "bg-teal-200",
  "bg-sky-100",
  "bg-sky-200",
  "bg-violet-100",
  "bg-violet-200",
  "bg-orange-100",
  "bg-orange-200",
];

export default function AvatarWithFallback({
  avatarable: avatareable,
  className,
  size = "md",
  fallback,
  ...rest
}: AvatarWithFallbackProps) {
  const initials = (avatareable.name || "A")[0].toLocaleUpperCase();

  return (
    <Avatar
      className={cn(
        "rounded-full hover:text-primary hover:bg-primary-foreground",
        SIZE_CHART[size],
        className,
      )}
      {...rest}
    >
      {avatareable.image && (
        <AvatarImage src={avatareable.image} alt={avatareable.name} />
      )}
      <AvatarFallback
        className={cn(
          TEXT_SIZE_CHART[size],
          FALLBACK_COLORS[initials.charCodeAt(0) % FALLBACK_COLORS.length],
        )}
      >
        <span>{fallback || initials}</span>
      </AvatarFallback>
    </Avatar>
  );
}
